<template>
  <div class="d-flex flex-column flex-grow-1 rounded">
    <BaseToolBar
      title="Habilitations"
      subtitle="Liste des habilitations disponibles"
    >
      <BaseButton
        class="btn btn-primary ml-3"
        v-tooltip="'Ajouter une habilitation'"
        @click="storeHabilitation"
        :loading="loading"
        icon="plus"
        text="Ajouter"
      ></BaseButton>
    </BaseToolBar>
    <trame-uploader
      id="document_habilitation_carnet"
      :file_system="'HABILITATIONS'"
      title="Exportez à partir d'un modèle"
      :data="exportData"
    ></trame-uploader>
    <div class="rounded shadow-sm mb-3 pl-3 pr-3" v-background-3>
      <uploader
        :id="'document_habilitation'"
        url="/upload/document"
        file_system="SIRH"
        :file_field="'TH2B'"
        :file_key="$route.params.id"
        title="Titres d'habilitations signés"
      ></uploader>
    </div>
    <div class="d-flex flex-column flex-md-row">
      <HabilitationsInfos
        class="mb-3"
        export="TH"
        :formations="formations"
        :vms="vms"
        :data="data"
      ></HabilitationsInfos>
      <div class="d-flex flex-column flex-md-row w-100">
        <div class="flex-grow-1 d-flex flex-column">
          <h5>Habilitations</h5>
          <div v-for="h in habilitations" :key="h.id">
            <habilitations-item
              :habilitation="h"
              v-if="
                h.habilitation_active === false &&
                  h.habilitation_date_validite === null
              "
            ></habilitations-item>
          </div>
          <div v-for="h in habilitations" :key="'actives_' + h.id">
            <habilitations-item
              :habilitation="h"
              v-if="h.habilitation_date_validite !== null"
            ></habilitations-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapMultiRowFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

import HabilitationsItem from "@/components/collaborateurs/profil/forms/habilitation/HabilitationsItem.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import HabilitationsInfos from "@/components/collaborateurs/profil/forms/habilitation/HabilitationsInfos.vue";
import BaseButton from "@/components/bases/Button.vue";
import TrameUploader from "@/components/application/TrameUploader.vue";
import Uploader from "@/components/bases/Uploader.vue";

export default {
  components: {
    HabilitationsItem,
    HabilitationsInfos,
    BaseToolBar,
    TrameUploader,
    Uploader,
    BaseButton
  },
  data() {
    return {
      newHabilitation: { habilitation_collaborateur_id: 0 },
      feedback: {},
      showActives: true,
      loading: false
    };
  },
  methods: {
    ...mapActions({
      _storeHabilitation: "collaborateurs/createHabilitation"
    }),
    storeHabilitation: function() {
      this.feedback = {};
      this.loading = true;
      this._storeHabilitation(this.newHabilitation)
        //.catch(error : (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    getHabilitationLibelle: function(id) {
      let rep = "";
      this.data.habilitationLibelles.forEach(d => {
        if (d.id === id) {
          rep = d.description;
        }
      });
      return rep;
    },
    getHabilitationDomaine: function(id) {
      let rep = "";
      this.data.habilitationDomaines.forEach(d => {
        if (d.id === id) {
          rep = d.description;
        }
      });
      return rep;
    },
    getLibelleFormation: function(id) {
      let rep = "";
      this.data.formationLibelles.forEach(d => {
        if (d.id === id) {
          rep = d.description;
        }
      });
      return rep;
    },
    getOrganismeMedicalLibelle: function(id) {
      let rep = "";
      this.data.vmOrganismes.forEach(d => {
        if (d.id === id) {
          rep = d.description;
        }
      });
      return rep;
    },
    getOrganismeMedicalTelephone: function(id) {
      let rep = "";
      this.data.vmOrganismes.forEach(d => {
        if (d.id === id) {
          rep = d.telephone;
        }
      });
      return rep;
    },
    getMedecinLibelle: function(id) {
      let rep = "";
      this.data.vmMedecins.forEach(d => {
        if (d.id === id) {
          rep = d.description;
        }
      });
      return rep;
    },
    contratVersion: function(contrat) {
      // versions du contrat
      const contratVersions = contrat.contrat_version;

      if (!contratVersions) return {};

      // Nombre de versions disponibles
      const nbrVersions = contratVersions.length;
      if (nbrVersions < 1) {
        return {};
      }

      // Version antérieure à la date du jour (sur date d'effet)
      const contratVersionsFiltered = contratVersions.filter(
        v => this.$moment(v.date_effet) <= this.$moment()
      );

      // get array length
      const nbrVersionsFiltered = contratVersionsFiltered.length;

      // retour null si aucune version des paramètres du contrat
      if (nbrVersions >= 1 && nbrVersionsFiltered >= 1)
        return contratVersionsFiltered[nbrVersionsFiltered - 1];
      if (nbrVersions >= 1 && nbrVersionsFiltered === 0)
        return contratVersions[nbrVersions - 1];
      return {};
    }
  },
  mounted() {
    this.newHabilitation.habilitation_collaborateur_id = this.id;
  },
  computed: {
    ...mapFields("collaborateurs", [
      "collaborateur.id",
      "collaborateur.collaborateur_nom",
      "collaborateur.collaborateur_prenom",
      "collaborateur.collaborateur_carnet_acces",
      "collaborateur.collaborateur_sexe_id",
      "collaborateur.collaborateur_date_naissance",
      "collaborateur.collaborateur_adresse_naissance",
      "collaborateur.collaborateur_contrats",
      "collaborateur.collaborateur_avatar",
      "collaborateur.collaborateur_adresse"
    ]),
    ...mapMultiRowFields("collaborateurs", [
      "habilitations",
      "formations",
      "vms"
    ]),
    ...mapGetters({
      data: "collaborateurs/data"
    }),

    exportData: function() {
      return {
        values: {
          numero_carnet: this.collaborateur_carnet_acces,
          nom: this.collaborateur_nom,
          prenom: this.collaborateur_prenom,
          date_naissance: this.$moment(
            this.collaborateur_date_naissance
          ).format("DD/MM/YYYY"),
          ville_naissance: this.collaborateur_adresse_naissance,
          sexe: this.collaborateur_sexe_id == 1 ? "M" : "F",
          date: this.$moment().format("DD/MM/YYYY"),
          metier: this.contratData.metier,
          societe: this.contratData.societe,
          num_siret: this.contratData.siret,
          societe_adresse: this.contratData.adresse,
          societe_tel: this.contratData.Tel,
          medecin: this.medicalData.medecin,
          organisme: this.medicalData.organisme,
          organisme_habilite:
            this.medicalData.service_nucleaire == 1 ? "Oui" : "Non",
          collaborateur_adresse: this.collaborateur_adresse
        },
        tablesSize: 9,
        tables: [
          {
            key: "tableau_scn",
            data: {
              header: [
                { id: 1, text: "Formation" },
                { id: 1, text: "N° Attestation" },
                { id: 2, text: "Délivrance" },
                { id: 3, text: "Organisme" }
              ],
              body: this.volet2Data.body
            }
          },
          {
            key: "tableau_csq",
            data: {
              header: [
                { id: 1, text: "Formation" },
                { id: 1, text: "N° Attestation" },
                { id: 2, text: "Délivrance" },
                { id: 3, text: "N°CEFRI" }
              ],
              body: this.volet3Data.body
            }
          },
          {
            key: "tableau_pr",
            data: {
              header: [
                { id: 1, text: "Formation" },
                { id: 1, text: "N° Attestation" },
                { id: 2, text: "Délivrance" },
                { id: 3, text: "N°CEFRI" }
              ],
              body: this.volet4Data.body
            }
          },
          {
            key: "tableau_rp",
            data: {
              header: [
                { id: 1, text: "Formation" },
                { id: 1, text: "N° Attestation" },
                { id: 2, text: "Délivrance" },
                { id: 3, text: "N°CEFRI" }
              ],
              body: this.volet4Data.body
            }
          },
          {
            key: "tableau_stars",
            data: {
              header: [
                { id: 1, text: "Formation" },
                { id: 1, text: "N° Attestation" },
                { id: 2, text: "Délivrance" },
                { id: 3, text: "Organisme" }
              ],
              body: this.volet5Data.body
            }
          },
          {
            key: "tableau_habilitations",
            data: {
              header: [
                { id: 1, text: "Habilitation" },
                { id: 1, text: "Domaine" },
                { id: 2, text: "Validité" }
              ],
              body: this.volet7Data.body
            }
          }
        ],
        images: [
          {
            key: "avatar",
            path: this.collaborateur_avatar,
            width: "3cm",
            height: "3cm",
            ratio: true
          }
        ]
      };
    },

    ThData: function() {
      let data = this.habilitations;
      let head = [["Habilitation", "Domaine", "validité"]];
      let body = [];
      let dataVm = "";

      if (data) {
        data.forEach(hab => {
          if (hab.habilitation_active) {
            const habilitation = this.getHabilitationLibelle(
              hab.habilitation_libelle_id
            );
            const domaine = this.getHabilitationDomaine(
              hab.habilitation_domaine_id
            );
            const validite = this.$moment(
              hab.habilitation_date_validite
            ).format("DD/MM/YYYY");

            body.push([habilitation, domaine, validite]);
          }
        });
      }

      this.vms.forEach(vm => {
        if (vm.vm_active) {
          if (vm.vm_date_validite) {
            dataVm =
              "Aptitude médicale valide jusqu'au " +
              this.$moment(vm.vm_date_validite).format("DD/MM/YYYY");
          }
        }
      });

      if (dataVm === "") {
        dataVm = "Aucune aptitude médicale valide";
      }

      return {
        head: head,
        body: body,
        dataVm: dataVm
      };
    },

    volet1Data: function() {
      return {
        collaborateur_nom: this.collaborateur_nom,
        collaborateur_prenom: this.collaborateur_prenom,
        collaborateur_carnet_acces: this.collaborateur_carnet_acces,
        collaborateur_sexe_id: this.collaborateur_sexe_id,
        collaborateur_date_naissance: this.collaborateur_date_naissance,
        collaborateur_adresse_naissance: this.collaborateur_adresse_naissance
      };
    },

    volet2Data: function() {
      let body = [];
      this.formations.forEach(frm => {
        if (
          this.getLibelleFormation(frm.formation_libelle_id) == "SCN1" ||
          this.getLibelleFormation(frm.formation_libelle_id) == "SCN2"
        ) {
          let lib = "";
          if (frm.organisme && frm.organisme.num_scn)
            lib = frm.organisme.num_scn;
          body.push([
            this.getLibelleFormation(frm.formation_libelle_id),
            frm.formation_attestation,
            this.$moment(frm.formation_date_fin).format("DD/MM/YYYY"),
            lib
          ]);
        }
      });

      return {
        collaborateur_nom: this.collaborateur_nom,
        collaborateur_prenom: this.collaborateur_prenom,
        collaborateur_carnet_acces: this.collaborateur_carnet_acces,
        collaborateur_sexe_id: this.collaborateur_sexe_id,
        collaborateur_date_naissance: this.collaborateur_date_naissance,
        collaborateur_adresse_naissance: this.collaborateur_adresse_naissance,
        head: [["Formation", "N° Attestation", "délivrance", "N° Organisme"]],
        body: body
      };
    },

    volet3Data: function() {
      let body = [];

      this.formations.forEach(frm => {
        if (
          this.getLibelleFormation(frm.formation_libelle_id) == "CSQ" ||
          this.getLibelleFormation(frm.formation_libelle_id) == "QSP"
        ) {
          let lib = "";
          if (frm.organisme && frm.organisme.num_scn)
            lib = frm.organisme.num_scn;
          body.push([
            this.getLibelleFormation(frm.formation_libelle_id),
            frm.formation_attestation,
            this.$moment(frm.formation_date_fin).format("DD/MM/YYYY"),
            lib
          ]);
        }
      });

      return {
        collaborateur_nom: this.collaborateur_nom,
        collaborateur_prenom: this.collaborateur_prenom,
        collaborateur_carnet_acces: this.collaborateur_carnet_acces,
        collaborateur_sexe_id: this.collaborateur_sexe_id,
        collaborateur_date_naissance: this.collaborateur_date_naissance,
        collaborateur_adresse_naissance: this.collaborateur_adresse_naissance,
        head: [["Formation", "N° Attestation", "délivrance", "N° CEFRI"]],
        body: body
      };
    },

    volet4Data: function() {
      let body = [];
      this.formations.forEach(frm => {
        if (
          this.getLibelleFormation(frm.formation_libelle_id) == "RP2 RN" ||
          this.getLibelleFormation(frm.formation_libelle_id) == "RP1 RN" ||
          this.getLibelleFormation(frm.formation_libelle_id) == "PR1 RNE" ||
          this.getLibelleFormation(frm.formation_libelle_id) == "PR1 CR" ||
          this.getLibelleFormation(frm.formation_libelle_id) == "PR1 CC"
        ) {
          // Correction du bug : absence d'organisme sélectionné
          let n_cefri = "";
          if (frm.organisme && frm.organisme.num_cefri)
            n_cefri = frm.organisme.num_cefri;

          body.push([
            this.getLibelleFormation(frm.formation_libelle_id),
            frm.formation_attestation,
            this.$moment(frm.formation_date_fin).format("DD/MM/YYYY"),
            n_cefri
          ]);
        }
      });

      return {
        collaborateur_nom: this.collaborateur_nom,
        collaborateur_prenom: this.collaborateur_prenom,
        collaborateur_carnet_acces: this.collaborateur_carnet_acces,
        collaborateur_sexe_id: this.collaborateur_sexe_id,
        collaborateur_date_naissance: this.collaborateur_date_naissance,
        collaborateur_adresse_naissance: this.collaborateur_adresse_naissance,
        head: [["Formation", "N° Attestation", "délivrance", "N° Organisme"]],
        body: body
      };
    },

    volet5Data: function() {
      let body = [];
      this.formations.forEach(frm => {
        if (
          this.getLibelleFormation(frm.formation_libelle_id) == "STARS 1" ||
          this.getLibelleFormation(frm.formation_libelle_id) == "STARS 2" ||
          this.getLibelleFormation(frm.formation_libelle_id) == "STARS 3" ||
          this.getLibelleFormation(frm.formation_libelle_id) == "STARS 4" ||
          this.getLibelleFormation(frm.formation_libelle_id) == "STARS 5"
        ) {
          let lib = "";
          if (frm.organisme && frm.organisme.num_scn)
            lib = frm.organisme.num_scn;
          body.push([
            this.getLibelleFormation(frm.formation_libelle_id),
            frm.formation_attestation,
            this.$moment(frm.formation_date_fin).format("DD/MM/YYYY"),
            lib
          ]);
        }
      });

      return {
        collaborateur_nom: this.collaborateur_nom,
        collaborateur_prenom: this.collaborateur_prenom,
        collaborateur_carnet_acces: this.collaborateur_carnet_acces,
        collaborateur_sexe_id: this.collaborateur_sexe_id,
        collaborateur_date_naissance: this.collaborateur_date_naissance,
        collaborateur_adresse_naissance: this.collaborateur_adresse_naissance,
        head: [["Formation", "N° Attestation", "délivrance", "N° Organisme"]],
        body: body
      };
    },

    contratData: function() {
      let _contrat = {};
      let _contratLength = this.collaborateur_contrats.length;
      if (_contratLength >= 1) {
        _contrat = this.collaborateur_contrats[_contratLength - 1];
      }

      _contrat = this.contratVersion(_contrat);

      if (_contrat.contrat_siret.siret_societe.description == undefined) {
        _contrat.contrat_siret.siret_societe.description = "";
      }

      if (_contrat.contrat_siret.siret == undefined) {
        _contrat.contrat_siret.siret = "";
      }

      if (_contrat.contrat_siret.siret_adresse == undefined) {
        _contrat.contrat_siret.siret_adresse = "";
      }

      if (_contrat.contrat_siret.siret_tel == undefined) {
        _contrat.contrat_siret.siret_tel = "";
      }

      return {
        metier: _contrat.contrat_libelle,
        societe: _contrat.contrat_siret.siret_societe.description,
        siret: _contrat.contrat_siret.siret,
        adresse: _contrat.contrat_siret.siret_adresse,
        Tel: _contrat.contrat_siret.siret_tel
      };
    },

    medicalData: function() {
      let data = {};
      this.vms.forEach(vm => {
        if (vm.vm_active) {
          if (vm.vm_date_validite) {
            data = vm;
          }
        }
      });

      return {
        medecin: this.getMedecinLibelle(data.vm_medecin_id),
        organisme: this.getOrganismeMedicalLibelle(data.vm_organisme_id),
        tel: this.getOrganismeMedicalTelephone(data.vm_organisme_id),
        service_nucleaire: 1
      };
    },

    volet6Data: function() {
      let _contrat = {};
      let _contratLength = this.collaborateur_contrats.length;
      if (_contratLength >= 1) {
        _contrat = this.collaborateur_contrats[_contratLength - 1];
      }

      _contrat = this.contratVersion(_contrat);

      if (_contrat.contrat_siret.siret_societe.description == undefined) {
        _contrat.contrat_siret.siret_societe.description = "";
      }

      if (_contrat.contrat_siret.siret == undefined) {
        _contrat.contrat_siret.siret = "";
      }

      if (_contrat.contrat_siret.siret_adresse == undefined) {
        _contrat.contrat_siret.siret_adresse = "";
      }

      if (_contrat.contrat_siret.siret_tel == undefined) {
        _contrat.contrat_siret.siret_tel = "";
      }

      return {
        collaborateur_nom: this.collaborateur_nom,
        collaborateur_prenom: this.collaborateur_prenom,
        collaborateur_carnet_acces: this.collaborateur_carnet_acces,
        collaborateur_sexe_id: this.collaborateur_sexe_id,
        collaborateur_date_naissance: this.collaborateur_date_naissance,
        collaborateur_adresse_naissance: this.collaborateur_adresse_naissance,
        societe: _contrat.contrat_siret.siret_societe.description,
        siret: _contrat.contrat_siret.siret,
        adresse: _contrat.contrat_siret.siret_adresse,
        tel: _contrat.contrat_siret.siret_tel
      };
    },

    volet7Data: function() {
      let data = this.habilitations;
      let head = [["Habilitation", "Domaine", "validité"]];
      let body = [];
      let _contrat = {};
      let _contratLength = this.collaborateur_contrats.length;
      if (_contratLength >= 1) {
        _contrat = this.collaborateur_contrats[_contratLength - 1];
      }

      _contrat = this.contratVersion(_contrat);

      if (data) {
        data.forEach(hab => {
          if (hab.habilitation_active) {
            const habilitation = this.getHabilitationLibelle(
              hab.habilitation_libelle_id
            );
            const domaine = this.getHabilitationDomaine(
              hab.habilitation_domaine_id
            );
            const validite = this.$moment(
              hab.habilitation_date_validite
            ).format("DD/MM/YYYY");

            body.push([habilitation, domaine, validite]);
          }
        });
      }

      if (_contrat.contrat_libelle == undefined) {
        _contrat.contrat_libelle = "";
      }

      return {
        collaborateur_nom: this.collaborateur_nom,
        collaborateur_prenom: this.collaborateur_prenom,
        collaborateur_carnet_acces: this.collaborateur_carnet_acces,
        collaborateur_sexe_id: this.collaborateur_sexe_id,
        collaborateur_date_naissance: this.collaborateur_date_naissance,
        collaborateur_adresse_naissance: this.collaborateur_adresse_naissance,
        contrat_poste: _contrat.contrat_libelle,
        head: head,
        body: body
      };
    },

    volet8Data: function() {
      let data = { medecin: {}, organisme: {} };

      this.vms.forEach(vm => {
        if (vm.vm_active) {
          if (vm.vm_date_validite) {
            data = JSON.parse(JSON.stringify(vm));
          }
        }
      });

      if (data.medecin.description == undefined) {
        data.medecin.description = "";
      }

      if (data.organisme.description == undefined) {
        data.organisme.description = "";
      }

      if (data.organisme.telephone == undefined) {
        data.organisme.telephone = "";
      }

      return {
        collaborateur_nom: this.collaborateur_nom,
        collaborateur_prenom: this.collaborateur_prenom,
        collaborateur_carnet_acces: this.collaborateur_carnet_acces,
        collaborateur_sexe_id: this.collaborateur_sexe_id,
        collaborateur_date_naissance: this.collaborateur_date_naissance,
        collaborateur_adresse_naissance: this.collaborateur_adresse_naissance,
        medecin: data.medecin.description,
        organisme: data.organisme.description,
        tel: data.organisme.telephone,
        service_nucleaire: 1
      };
    }
  }
};
</script>
<style lang="css"></style>
